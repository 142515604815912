@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::before,
::after {
  --tw-content: '';
}



html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
}

body {
  margin: 0;
  line-height: inherit;
}


hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}


abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}


code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  /* 1 */
  background-color: transparent;
  /* 2 */
  background-image: none;
  /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/

dialog {
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}


input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}


:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden] {
  display: none;
}

body {
  font-family: "Inter", sans-serif;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
}

@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-left-9 {
  left: -2.25rem;
}

.-right-6 {
  right: -1.5rem;
}

.-top-6 {
  top: -1.5rem;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0px;
}

.left-1 {
  left: 0.25rem;
}

.left-4 {
  left: 1rem;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0px;
}

.right-1 {
  right: 0.25rem;
}

.right-4 {
  right: 1rem;
}

.right-8 {
  right: 2rem;
}

.right-\[-50px\] {
  right: -50px;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-4 {
  top: 1rem;
}

.top-\[60px\] {
  top: 60px;
}

.top-full {
  top: 100%;
}

.-z-10 {
  z-index: -10;
}

.-z-\[1\] {
  z-index: -1;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[999\] {
  z-index: 999;
}

.-m-5 {
  margin: -1.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-\[6px\] {
  margin-top: 6px;
  margin-bottom: 6px;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[14px\] {
  margin-bottom: 14px;
}

.mb-\[18px\] {
  margin-bottom: 18px;
}

.mb-\[22px\] {
  margin-bottom: 22px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[50px\] {
  margin-bottom: 50px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.mb-\[6px\] {
  margin-bottom: 6px;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[70px\] {
  height: 70px;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.w-10 {
  width: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-60 {
  width: 15rem;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[330px\] {
  width: 330px;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[70px\] {
  width: 70px;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[370px\] {
  max-width: 370px;
}

.max-w-\[485px\] {
  max-width: 485px;
}

.max-w-\[50px\] {
  max-width: 50px;
}

.max-w-\[510px\] {
  max-width: 510px;
}

.max-w-\[515px\] {
  max-width: 515px;
}

.max-w-\[520px\] {
  max-width: 520px;
}

.max-w-\[525px\] {
  max-width: 525px;
}

.max-w-\[530px\] {
  max-width: 530px;
}

.max-w-\[540px\] {
  max-width: 540px;
}

.max-w-\[570px\] {
  max-width: 570px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[780px\] {
  max-width: 780px;
}

.max-w-\[80px\] {
  max-width: 80px;
}

.max-w-\[845px\] {
  max-width: 845px;
}

.max-w-\[88px\] {
  max-width: 88px;
}

.max-w-full {
  max-width: 100%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[25deg\] {
  --tw-rotate: 25deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[14px\] {
  gap: 14px;
}

.gap-\[22px\] {
  gap: 22px;
}

.gap-\[2px\] {
  gap: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[14px\] {
  border-radius: 14px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-\[\#8890A4\] {
  --tw-border-opacity: 1;
  border-color: rgb(136 144 164 / var(--tw-border-opacity));
}

.border-\[\#f1f1f1\] {
  --tw-border-opacity: 1;
  border-color: rgb(241 241 241 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(55 88 249 / var(--tw-border-opacity));
}

.border-stroke {
  --tw-border-opacity: 1;
  border-color: rgb(223 228 234 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-opacity-40 {
  --tw-border-opacity: 0.4;
}

.bg-\[\#090E34\] {
  --tw-bg-opacity: 1;
  background-color: rgb(9 14 52 / var(--tw-bg-opacity));
}

.bg-\[\#1C9CEA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(28 156 234 / var(--tw-bg-opacity));
}

.bg-\[\#4064AC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(64 100 172 / var(--tw-bg-opacity));
}

.bg-\[\#D64937\] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 73 55 / var(--tw-bg-opacity));
}

.bg-\[\#E9F9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 249 255 / var(--tw-bg-opacity));
}

.bg-\[\#F4F7FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 255 / var(--tw-bg-opacity));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
}

.bg-gray-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(55 88 249 / var(--tw-bg-opacity));
}

.bg-primary\/5 {
  background-color: rgb(55 88 249 / 0.05);
}

.bg-primary\/\[0\.08\] {
  background-color: rgb(55 88 249 / 0.08);
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 150 / var(--tw-bg-opacity));
}

.bg-stroke {
  --tw-bg-opacity: 1;
  background-color: rgb(223 228 234 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.bg-white\/\[0\.12\] {
  background-color: rgb(255 255 255 / 0.12);
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-dark-700 {
  --tw-gradient-from: #090e34b3 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(9 14 52 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-stroke\/0 {
  --tw-gradient-from: rgb(223 228 234 / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(223 228 234 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-stroke {
  --tw-gradient-to: rgb(223 228 234 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #DFE4EA var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-stroke\/0 {
  --tw-gradient-to: rgb(223 228 234 / 0) var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-center {
  -o-object-position: center;
  object-position: center;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.px-\[22px\] {
  padding-left: 22px;
  padding-right: 22px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[60px\] {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[60px\] {
  padding-bottom: 60px;
}

.pr-16 {
  padding-right: 4rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-\[120px\] {
  padding-top: 120px;
}
.pt-\[80px\] {
  padding-top: 80px;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[35px\] {
  font-size: 35px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-\[1\.14\] {
  line-height: 1.14;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-\[28px\] {
  line-height: 28px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-loose {
  line-height: 2;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.-tracking-\[2px\] {
  letter-spacing: -2px;
}

.text-body-color {
  --tw-text-opacity: 1;
  color: rgb(99 115 129 / var(--tw-text-opacity));
}

.text-body-secondary {
  --tw-text-opacity: 1;
  color: rgb(136 153 168 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.text-dark-3 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-dark-6 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-1 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-7 {
  --tw-text-opacity: 1;
  color: rgb(206 212 218 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/60 {
  color: rgb(255 255 255 / 0.6);
}

.text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.opacity-0 {
  opacity: 0;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-1 {
  --tw-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.40);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2 {
  --tw-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0px 5px 12px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-form {
  --tw-shadow: 0px 1px 55px -11px rgba(0, 0, 0, 0.01);
  --tw-shadow-colored: 0px 1px 55px -11px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-pricing {
  --tw-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0px 0px 40px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-testimonial {
  --tw-shadow: 0px 10px 20px 0px rgba(92, 115, 160, 0.07);
  --tw-shadow-colored: 0px 10px 20px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 88 249 / var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[top\] {
  transition-property: top;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.sticky {
  position: fixed;
  z-index: 9999;
  background-color: rgb(255 255 255 / 0.8);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

:is(.dark .sticky) {
  background-color: rgb(17 25 40 / 0.8);
}

.sticky {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-logo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sticky #navbarToggler span {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
}

:is(.dark .sticky #navbarToggler span) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.sticky #navbarCollapse li>a {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.sticky #navbarCollapse li>a:hover {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
  opacity: 1;
}

:is(.dark .sticky #navbarCollapse li > a) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .sticky #navbarCollapse li > a:hover) {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

#navbarCollapse li .ud-menu-scroll.active {
  opacity: 0.7;
}

.sticky #navbarCollapse li .ud-menu-scroll.active {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
  opacity: 1;
}

.sticky .loginBtn {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.sticky .loginBtn:hover {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
  opacity: 1;
}

:is(.dark .sticky .loginBtn) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .sticky .loginBtn:hover) {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

.sticky .signUpBtn {
  --tw-bg-opacity: 1;
  background-color: rgb(55 88 249 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.sticky .signUpBtn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 68 200 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.sticky #themeSwitcher~span {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

:is(.dark .sticky #themeSwitcher ~ span) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.navbarTogglerActive>span:nth-child(1) {
  top: 7px;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navbarTogglerActive>span:nth-child(2) {
  opacity: 0;
}

.navbarTogglerActive>span:nth-child(3) {
  top: -8px;
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.common-carousel .swiper-button-next:after,
.common-carousel .swiper-button-prev:after {
  display: none;
}

.common-carousel .swiper-button-next,
.common-carousel .swiper-button-prev {
  position: static !important;
  margin: 0px;
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
  --tw-shadow: 0px 8px 15px 0px rgba(72, 72, 138, 0.08);
  --tw-shadow-colored: 0px 8px 15px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.common-carousel .swiper-button-next:hover,
.common-carousel .swiper-button-prev:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 88 249 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .common-carousel .swiper-button-next),
:is(.dark .common-carousel .swiper-button-prev) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.common-carousel .swiper-button-next svg,
.common-carousel .swiper-button-prev svg {
  height: auto;
  width: auto;
}

.placeholder\:text-body-color\/60::-moz-placeholder {
  color: rgb(99 115 129 / 0.6);
}

.placeholder\:text-body-color\/60::placeholder {
  color: rgb(99 115 129 / 0.6);
}

.placeholder\:text-dark-6::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-dark-6::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-white\/60::-moz-placeholder {
  color: rgb(255 255 255 / 0.6);
}

.placeholder\:text-white\/60::placeholder {
  color: rgb(255 255 255 / 0.6);
}

.hover\:border-blue-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(27 68 200 / var(--tw-border-opacity));
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 88 249 / var(--tw-border-opacity));
}

.hover\:bg-\[\#0BB489\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(11 180 137 / var(--tw-bg-opacity));
}

.hover\:bg-blue-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 68 200 / var(--tw-bg-opacity));
}

.hover\:bg-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
}

.hover\:bg-gray-2:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 88 249 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}

.hover\:text-body-color:hover {
  --tw-text-opacity: 1;
  color: rgb(99 115 129 / var(--tw-text-opacity));
}

.hover\:text-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 88 249 / var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:shadow-none:focus-visible {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:rotate-6 {
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

:is(.dark .dark\:block) {
  display: block;
}

:is(.dark .dark\:hidden) {
  display: none;
}

:is(.dark .dark\:border-dark-3) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-dark) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-2) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 42 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-3) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-700) {
  background-color: #090e34b3;
}

:is(.dark .dark\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is(.dark .dark\:via-dark-3) {
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #374151 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

:is(.dark .dark\:text-dark-6) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:shadow-none) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .dark\:hover\:border-primary:hover) {
  --tw-border-opacity: 1;
  border-color: rgb(55 88 249 / var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:text-primary:hover) {
  --tw-text-opacity: 1;
  color: rgb(55 88 249 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:border-primary:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(55 88 249 / var(--tw-border-opacity));
}

@media (min-width: 540px) {
  .sm\:left-9 {
    left: 2.25rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-\[160px\] {
    height: 160px;
  }

  .sm\:h-\[220px\] {
    height: 220px;
  }

  .sm\:h-\[400px\] {
    height: 400px;
  }

  .sm\:h-\[60px\] {
    height: 60px;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:max-w-\[60px\] {
    max-width: 60px;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-\[28px\] {
    font-size: 28px;
  }

  .sm\:text-\[36px\] {
    font-size: 36px;
  }

  .sm\:text-\[40px\] {
    font-size: 40px;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .sm\:leading-\[1\.44\] {
    line-height: 1.44;
  }

  .sm\:leading-\[40px\] {
    line-height: 40px;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 720px) {
  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:h-\[346px\] {
    height: 346px;
  }

  .md\:h-\[400px\] {
    height: 400px;
  }

  .md\:h-\[540px\] {
    height: 540px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-7\/12 {
    width: 58.333333%;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:p-\[60px\] {
    padding: 60px;
  }

  .md\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  .md\:py-\[120px\] {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .md\:pt-\[130px\] {
    padding-top: 130px;
  }

  .md\:text-\[28px\] {
    font-size: 28px;
  }

  .md\:text-\[35px\] {
    font-size: 35px;
  }

  .md\:text-\[38px\] {
    font-size: 38px;
  }

  .md\:text-\[40px\] {
    font-size: 40px;
  }

  .md\:leading-\[1\.28\] {
    line-height: 1.28;
  }

  .md\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .md\:leading-\[1\.42\] {
    line-height: 1.42;
  }

  .md\:leading-\[1\.44\] {
    line-height: 1.44;
  }
}

@media (min-width: 960px) {
  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:top-\[110\%\] {
    top: 110%;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:mb-\[150px\] {
    margin-bottom: 150px;
  }

  .lg\:mb-\[70px\] {
    margin-bottom: 70px;
  }

  .lg\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mr-0 {
    margin-right: 0px;
  }

  .lg\:mt-\[60px\] {
    margin-top: 60px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[225px\] {
    height: 225px;
  }

  .lg\:h-\[400px\] {
    height: 400px;
  }

  .lg\:h-\[45\%\] {
    height: 45%;
  }

  .lg\:h-\[500px\] {
    height: 500px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-2\/12 {
    width: 16.666667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-5\/12 {
    width: 41.666667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-7\/12 {
    width: 58.333333%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:border-0 {
    border-width: 0px;
  }

  .lg\:bg-transparent {
    background-color: transparent;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-\[110px\] {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .lg\:py-\[115px\] {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  .lg\:py-\[90px\] {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-\[50px\] {
    padding-bottom: 50px;
  }

  .lg\:pb-\[70px\] {
    padding-bottom: 70px;
  }

  .lg\:pb-\[90px\] {
    padding-bottom: 90px;
  }

  .lg\:pl-0 {
    padding-left: 0px;
  }

  .lg\:pr-0 {
    padding-right: 0px;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pt-\[100px\] {
    padding-top: 100px;
  }

  .lg\:pt-\[120px\] {
    padding-top: 120px;
  }

  .lg\:pt-\[160px\] {
    padding-top: 160px;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .lg\:text-body-color {
    --tw-text-opacity: 1;
    color: rgb(99 115 129 / var(--tw-text-opacity));
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:visible {
    visibility: visible;
  }

  .group:hover .lg\:group-hover\:top-full {
    top: 100%;
  }

  .group:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .group:hover .lg\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  :is(.dark .dark\:lg\:bg-transparent) {
    background-color: transparent;
  }

  :is(.dark .dark\:lg\:text-dark-6) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

@media (min-width: 1140px) {
  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:h-\[310px\] {
    height: 310px;
  }

  .xl\:h-\[500px\] {
    height: 500px;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-2\/12 {
    width: 16.666667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-4\/12 {
    width: 33.333333%;
  }

  .xl\:w-5\/12 {
    width: 41.666667%;
  }

  .xl\:w-8\/12 {
    width: 66.666667%;
  }

  .xl\:gap-11 {
    gap: 2.75rem;
  }

  .xl\:p-14 {
    padding: 3.5rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-\[42px\] {
    font-size: 42px;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:leading-\[1\.21\] {
    line-height: 1.21;
  }
}

@media (min-width: 1320px) {
  .\32xl\:ml-20 {
    margin-left: 5rem;
  }

  .\32xl\:p-\[60px\] {
    padding: 60px;
  }
}

.w-2 {
  width: 70px;
}

.text-justify {
  text-align: justify;  
}

.text-uppercase {
  text-transform: uppercase;
}


.max-w-md {
  max-width: 28rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.md\:grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.lg\:grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}